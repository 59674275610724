import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/coverage.module.scss"

const Coverage = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <iframe
          src="https://docs.google.com/document/d/e/2PACX-1vRd-UV1zbh8QROC2Y3bIvbMB4gZ2VMPejYtI-bkkRDTEn16_ES85codLLjxTjZOiXv-JqWMQqn5BVfU/pub?embedded=true"
          frameborder="0"
          className={styles.form}
        >
          Loading…
        </iframe>
      </div>
    </Layout>
  )
}

export default Coverage
